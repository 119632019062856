<template>
  <header>
    <header-info class="py-10px"></header-info>
    <section :class="{ 'header-menu': true, 'fixed': isFixed }">
      <div class="header-menu__container">
        <nav>
          <router-link to="/catalog" class="nav-item catalog font-normal lg:hover:underline">
            <strong>Каталог товаров</strong>
          </router-link>
          <SearchForm v-if="!searchFormUnder" @search="search"></SearchForm>
          <router-link to="/" class="nav-item font-normal lg:hover:underline">
            <strong>Главная</strong>
          </router-link>
          <router-link to="/blog" class="nav-item font-normal lg:hover:underline">Полезные статьи</router-link>
          <router-link to="/about" class="nav-item font-normal lg:hover:underline">О нас</router-link>
        </nav>
      </div>
      <SearchForm v-if="searchFormUnder" @search="search"></SearchForm>
    </section>
  </header>
</template>

<script>
import HeaderInfo from './HeaderInfo.vue'
import SearchForm from '@/components/UI/SeacrhForm'

export default {
  components: {
    HeaderInfo,
    SearchForm,
  },
  data() {
    return {
      searchFormUnder: false,
      isFixed: false,
      scrollStep: 130,
    }
  },
  methods: {
    search(text) {
      this.$router.push({
        name: 'catalog-by-search',
        params: { searchText: text },
      });
    },
    handleScroll() {
      const currentScrollY = window.scrollY;
      this.isFixed = currentScrollY >= this.scrollStep;
    },
    setScrollStep() {
      if (window.innerWidth <= 600) {
        this.scrollStep = 90;
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    if (window.innerWidth < 1200) {
      this.searchFormUnder = true;
    }
    this.setScrollStep();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';

header {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 2px solid $beige;
  margin-bottom: 20px;
}

.header-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 7px;
  max-width: 100%;
  background-color: white;
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
}

.header-menu__container {
  display: flex;
  flex-direction: row;
  max-width: 1440px;
  width: 100%;
  justify-content: center;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 50px;

  @media (max-width: 700px) {
    padding: 0;
  }
}

.nav-item {
  text-decoration: none;
  font-size: 20px;
  color: $text-dark;

  @media (max-width: 700px) {
    font-size: 16px;
  }

  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.catalog {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $orange-pink;
  padding: 0 15px;
  border-radius: 8px;
  height: 50px;

  @media (max-width: 1000px) {
    height: 40px;
  }

  @media (max-width: 700px) {
    height: 30px;
    padding: 0 10px;
    border-radius: 5px;
  }

  @media (max-width: 500px) {
    padding: 0 5px;
  }
}
</style>