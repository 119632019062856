<template>
  <img class="bg-cover bg-center bg-no-repeat rounded-[3px]" :src="imageUrl" alt="Image">
</template>

<script>
export default {
  props: {
    imageUrl: String,
  }
}
</script>

<style lang="scss" scoped>
img {
  width: 94px;
  min-width: 94px;
  height: 94px;
  min-height: 94px;

  @media (max-width: 1000px) {
    width: 69px;
    min-width: 69px;
    height: 69px;
    min-height: 69px;
  }
}
</style>