<template>
  <section v-if="!isProductLoading" class="product">
    <div class="product__info flex flex-row max-[700px]:flex-col justify-start max-[500px]:items-center gap-[20px]">
      <div class="produt__info__images flex flex-row max-[500px]:flex-col-reverse justify-center items-start gap-[10px]">
        <div v-if="product.images" class="adverse-images flex flex-col max-[500px]:flex-row justify-start items-center gap-[8px]">
          <AdverseImage
            v-for="image in product.images"
            :key="image"
            :imageUrl="image.image"
          ></AdverseImage>
        </div>
        <img class="main-photo object-cover bg-cover bg-center rounded-[10px]" :src="product.main_photo" alt="Main photo">
      </div>
      <div class="produt__info__description flex flex-col justify-start items-start">
        <h1 class="text-[32px] font-bold text-black text-left">{{ product.name }}</h1>
        <p v-if="product.adding_names" class="adding-names text-[16px] max-[700px]:text-[12px] font-thin">{{ product.adding_names }}</p>
        <div class="flex flex-col items-start max-[700px]:flex-row max-[700px]:justify-between max-[700px]:items-center w-full">
          <h1 class="price text-[30px] font-semibold">{{ validatePrice }} &#8381;</h1>
          <p class="mb-[15px] max-[700px]:mb-0 mr-[60px] max-[500px]:mr-0 text-[16px] font-extralight">Всего в наличии: {{ product.count }} шт</p>
        </div>
        <p class="description text-left" v-if="product.description && product.description.length <= 800"><span class="text-[20px]"><strong>Описание</strong></span><br>{{ product.description }}</p>
        <p class="description text-left" v-else-if="!product.description">Описание отсутсвует</p>
      </div>
    </div>
    <div v-if="product.description && product.description.length > 800" class="mt-[20px] max-[700px]:mt-[5px]">
      <p class="description text-left"><span class="text-[20px]"><strong>Описание</strong></span><br>{{ product.description }}</p>
    </div>
  </section>
  <LoadingMark v-else></LoadingMark>
  <ProductSample
    v-if="!isSampleLoading && thisCategoryProducts.length >= 2"
    title="Ещё товары из этой категории"
    path=""
    :inputProducts="thisCategoryProducts"
  ></ProductSample>
  <LoadingMark v-else-if="isSampleLoading"></LoadingMark>
</template>


<script>
import axiosInstance from '@/const.js'
import AdverseImage from '@/components/ProductPage/AdverseImage'
import ProductSample from '@/components/HomePage/ProductSample'
import LoadingMark from '@/components/UI/LoadingMark'

export default {
  components: {
    AdverseImage,
    ProductSample,
    LoadingMark,
  },
  data() {
    return {
      product: {},
      thisCategoryProducts: [],
      isProductLoading: false,
      isSampleLoading: false,
    }
  },
  computed: {
    validatePrice() {
      return String(
        this.product.price
      ).substring(0, String(this.product.price).length - 3);
    },
  },
  methods: {
    async getProduct() {
      try {
        this.isProductLoading = true;
        const url = `catalog/products/${this.$route.params.article}/`;
        const response = await axiosInstance.get(url);
        if (response.status !== 200) {
          throw new Error(`Status ${response.status}`);
        }
        this.product = response.data;
        this.getCategorySample();
      } catch (e) {
        alert(e);
      } finally {
        this.isProductLoading = false;
      }
    },
    async getCategorySample() {
      try {
        this.isSampleLoading = true;
        const response = await axiosInstance.get(
          'catalog/products/',
          {
            params: {
              category: this.product.category,
            }
          }
        );
        if (response.status !== 200) {
          throw new Error(`Status ${response.status}`);
        }
        if (response.data.length > 15) {
          this.thisCategoryProducts = response.data.slice(0, 15);
        } else {
          this.thisCategoryProducts = response.data;
        }
      } catch (e) {
        alert(e);
      } finally {
        this.isSampleLoading = false;
      }
    },
  },
  mounted() {
    this.getProduct();
  }
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';

.main-photo {
  width: 400px;
  min-width: 400px;
  height: 400px;
  min-height: 400px;

  @media (max-width: 1000px) {
    width: 300px;
    min-width: 300px;
    height: 300px;
    min-height: 300px;
  }
}

.price {
  color: $price-color;

  @media (max-width: 760px) {
    font-size: 24px;
  }
}

h1 {
  @media (max-width: 1024px) {
    font-size: 20px;
  }
}

.description {
  font-size: 16px;

  @media (max-width: 700px) {
    font-size: 12px;
  }
}

.produt__info__description {
  @media (max-width: 500px) {
    width: 300px;
  }
}

.adding-names {
  text-align: start;
  color: #828282;
}
</style>