<template>
  <section class="header-info flex justify-center items-center py-[5px]">
    <div class="flex flex-row justify-between items-center max-w-[1440px]">
      <router-link to="/">
        <logo-item :isHeader="true"></logo-item>
      </router-link>
      <div class="header-items flex flex-row justify-between items-center mx-[50px]">
        <div class="header-item">
          <address class="leading-tight text-[16px] max-[1000px]:text-[14px] max-[800px]:text-[12px] max-[600px]:text-[10px] max-[500px]:text-[7px]">
            <a class="yandex-map lg:hover:underline" href="https://yandex.ru/maps/org/tsvetochnaya_palitra/80568735750/?ll=43.934146%2C56.193252&z=14" target="_blank"><strong>Адрес:</strong> Полевая улица, 52, село Ближнее Борисово, Кстовский муниципальный округ, Нижегородская область</a>
          </address>
        </div>
        <div class="header-item">
          <p class="leading-tight text-[16px] max-[1000px]:text-[14px] max-[800px]:text-[12px] max-[600px]:text-[10px] max-[500px]:text-[7px]">
            <em><strong>Режим работы:</strong> ежедневно c 10:00 до 18:00, без выходных</em>
            <br>
            <em><strong>Оплата:</strong> наличными, картой, переводом или по QR-коду</em>
          </p>
        </div>
        <div class="header-item">
          <address class="text-[20px] max-[1000px]:text-[18px] max-[800px]:text-[16px] max-[600px]:text-[14px] max-[500px]:text-[9px]">
            <p class="leading-tight">
              <em><strong>Контакты:</strong><br><a href="tel:+79202538078">8 920 253 80 78</a></em>
            </p>
          </address>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import LogoItem from '@/components/LogoItem';

export default {
  components: {LogoItem},
}
</script>


<style lang="scss" scoped>
@import '@/assets/variables.scss';

.header-info {
  background-color: $beige;
  max-width: 100%;
}

.header-items {
  @media (max-width: 1000px) {
    margin-left: 25px;
    margin-right: 50px;
  }

  @media (max-width: 800px) {
    margin-left: 10px;
    margin-right: 20px;
  }

  @media (max-width: 600px) {
    margin-left: 0;
    margin-right: 10px;
  }
}

.header-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: start;
  max-width: 35%;
}

.yandex-map {
  text-decoration: none;
  color: black;
}

p {
  color: black;
}
</style>